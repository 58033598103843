﻿@use "../settings";
@use "../mixins";

@include mixins.tablet-up {
    .header-container {
        padding-top: 0;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            padding-bottom: 12px;
        }

        .logo {
            display: block;
            visibility: visible;
            flex-basis: 50%;
            width: auto;
            max-width: 313px;
            padding: 0 !important;
        }

        .search-and-meta {
            width: auto !important;
            padding: 0 !important;
            margin-left: auto;
        }
    }

    #divMeta {
        justify-content: flex-end;
    }    

    #divSearch {
        justify-content: flex-end;
        margin-bottom: 6px;
        .clsSearchBox {
            max-width: 155px;
            flex-shrink: 1;
        }
    }
}

@include mixins.media-up(869px) {
    .clsProactiveDisclosureButton {
        display: block;
        line-height: 43px;
        padding: 0 15px 0 41px;
        background: settings.$color-hover;
        color: #fff;
        position: relative;
        margin-right: 9px;

        &::before {
            content: "";
            background: url(../images/icon_proactive_disclosure.png);
            position: absolute;
            left: 17px;
            top: 50%;
            width: 15px;
            height: 16px;
            transform: translateY(-50%);
        }

        &:hover {
            color: #fff;
            background: settings.$color-primary;
        }
    }

    #divMeta {
        #google_translate_element {
            margin-right: auto;
            margin-left: 0;
        }

        .clear {
            display: none;
        }
    }

    #dropdown-language-control {
        .combo {
            font-size: 1.4rem;
        }
    }

    .clsAdvancedSearch {
        font-size: 1.4rem;
    }
}