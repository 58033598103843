﻿@use "settings";

@mixin media-up($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin tablet-up {
    @include media-up(settings.$tablet) {
        @content;
    }
}

@mixin desktop-up {
    @include media-up(settings.$desktop) {
        @content;
    }
}