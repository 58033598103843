﻿@use "sass:math";
@use "../mixins";

@include mixins.desktop-up {
    #divFooter {
        justify-content: space-between;

        .clsLogoFooter {
            max-width: 50%;
        }

        #divFooterInfo {
            flex-basis: 312px;
            max-width: none !important;
            padding: 0 !important;
            margin-left: 0;
        }

        #divFooterText {
            flex-basis: auto;
            width: auto;
            padding: 0 !important;
            text-align: left;
            margin-left: auto;
            flex-grow: 1;
            max-width: 688px;

            table {
                display: table;
                float: right;
            }

            tbody {
                display: table-row-group;
            }

            tr {
                display: table-row;
            }

            td {
                display: table-cell;
                vertical-align: top;
                padding-right: 6.6%;
                width: percentage(math.div(1, 3));
            }
        }
    }
}
