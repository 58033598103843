﻿@use "../mixins";
@use "../settings";

@include mixins.desktop-up {
    .navigation-primary {
        .max-width {
            padding-left: 0;
            padding-right: 0;
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
        }

        .nav-items {
            justify-content: space-between;

            .dropdown-toggle {
                display: none;
            }
        }

        #mobile-menu {
            ~ .nav-items {
                font-size: 1.3rem;

                .nav-item {
                    &:hover {
                        .dropdown {
                            max-height: var(--var-max-height);
                            padding-top: 18px;
                            padding-bottom: 18px;
                            overflow: visible;
                        }
                    }

                    .dropdown {
                        a {
                            padding-right: 20px !important;
                            display: block;
                            position: relative;

                            &:hover {
                                color: settings.$color-primary;
                            }

                            &::after {
                                content: "";
                                display: block;
                                border-right: 2px solid #fff;
                                border-top: 2px solid #fff;
                                width: 10px;
                                height: 10px;
                                transform: rotate(45deg) translateY(-50%);
                                position: absolute;
                                top: 50%;
                                right: 0;
                                opacity: 0;
                                transition: opacity ease-in-out 0.25s;
                            }

                            &:hover {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
