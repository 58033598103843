﻿@use "../settings";

.footer-container {
    background: url(../images/bg_footer_container.png) repeat top left;
    padding: 30px 0;
}

#divFooter {
    display: flex;
    flex-wrap: wrap;

    &,
    * {
        font-size: 1.5rem;
    }

    #divFooterInfo {
        max-width: 262px !important;
        margin: 0 auto;
    }

    #divFooterInfo,
    #divFooterText {
        flex-basis: 100%;
    }

    .d-flex {
        gap: 16px;
        align-items: center;
    }
}

.clsLogoFooter {
    margin-bottom: 16px;
}

#divContactInfo {
    display: block;
    margin-bottom: 22px;
}

#divPrivacyPolicy {
    #divFooter & {
        &,
        * {
            font-size: 1.2rem;
        }
    }

    color: #797979;
    padding-left: 32px;
    background: url(../images/bg_lock.png) no-repeat left center;
    margin-bottom: 19px;
    line-height: 1.2;
}

.clsContrastLow,
.clsContrastHigh {
    padding: 0 8px;
    line-height: 2.3rem;
    font-size: 1.1rem;
    color: #fff;
    background: #adacac;
    display: block;
    text-align: center;
    transition: background-color ease-in-out 0.25s;
    &:hover {
        color: #fff;
        background-color: settings.$color-primary;
    }
}
#divFontSize {
    display: flex;
    gap: 8px;
    align-items: center;
}
.clsSmaller,
.clsLarger {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: settings.$color-hover;
    border: 1px solid settings.$color-hover;
    display: block;
    text-align: center;
    line-height: 24px;
    transition: border-color ease-in-out 0.25s, color ease-in-out 0.25s;
    font-weight: 600;
    font-size: 1.2em;

    &:hover {
        color: settings.$color-primary;
        border-color: settings.$color-primary;
    }
}

.clsDropdownArrow,
.clsDropdownArrowRemove {
    position: relative;
    padding-right: 20px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(45deg) translateY(calc(-50% - 5px));
        border-right: 2px solid settings.$color-hover;
        border-bottom: 2px solid settings.$color-hover;
        opacity: 0;
        transition: opacity ease-in-out 0.25s;
        width: 10px;
        height: 10px;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }

    + .clsHasDropdown {
        height: 0;
        overflow: hidden;
    }
}

.clsDropdownArrowRemove {
    &::after {
        transform: translateY(calc(-50% + 5px)) rotate(-135deg);
    }
}

#divFooter {
    .clsHasDropdown {
        padding-left: 8px;

        a {
            font-size: .9em;
        }
    }
}