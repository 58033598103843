﻿@use "../settings";

#divBreadcrumb {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin: 26px 0;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 4px;

        li {
            &,
            a {
                color: #828384;
            }

            &::after {
                content: " /";
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
            a {
                &:hover {
                    color: settings.$color-hover;
                }
            }
        }
    }
}
