﻿@use "sass:math";
@use "../settings";

.layout {
    .navigation-primary {
        order: 1;
    }
}
.navigation-primary {
    background: settings.$color-primary;
    padding: 19.5px 0 22.7px;
    color: #fff;
    z-index: 1000;
    position: fixed;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;

    .max-width {
        padding: 0 22px;
        width: 100%;
    }

    .nav-items-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .logo {
            order: 1;
        }

        .mobile-menu-icon {
            order: 2;
            position: relative;
            z-index: 1001;
        }

        .nav-items {
            order: 3;
        }
    }

    #mobile-menu {
        display: none;

        & + label {
            cursor: pointer;
            width: 30px;
            height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
                display: block;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                background: #fff;
                transition: transform ease-in-out 0.25s;
            }
        }

        & ~ .nav-items {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: transform ease-in-out 0.25s;
            background: settings.$color-primary;
            z-index: 1000;
        }

        &:checked,
        &[checked] {
            & + label {
                position: fixed;
                right: 22px;

                span {
                    display: none;

                    &:first-child {
                        display: block;
                        transform: translateY(225%) rotate(45deg);
                    }

                    &:last-child {
                        display: block;
                        transform: translateY(-225%) rotate(-45deg);
                    }
                }
            }

            & ~ .nav-items {
                transform: translateX(0);
            }
        }
    }

    .nav-items {
        font-size: 1.8rem;
        list-style: none;
        padding: 76px 22px;
        margin: 0;

        a {
            padding: 12px 0;
            color: #fff;
            display: block;
        }

        .dropdown {
            max-height: 0;
            overflow: hidden;
            transition: max-height ease-in-out 0.25s;
        }

        .nav-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .dropdown-toggle {
                flex-basis: 8px;
            }

            .dropdown {
                flex-basis: 100%;
            }
        }

        .dropdown-toggle {
            padding: 12px 18px;
            display: flex;

            &::before {
                content: "";
                display: block;
                border: 8px solid transparent;
                border-bottom: none;
                border-top-color: #fff;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;
            visibility: hidden;

            &:checked,
            &[checked] {
                & ~ .dropdown-toggle {
                    &::before {
                        border-top: none;
                        border-bottom: 8px solid #fff;
                    }
                }

                & ~ .dropdown {
                    max-height: var(--var-max-height);
                }
            }
        }
    }
}
