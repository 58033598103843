﻿@use "../settings";

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    color: settings.$color-text;
    font-family: settings.$font-family;
}

html {
    font-size: 10px;
}

body {
    font-size: 1.5rem;
    line-height: 1.5;
}

* {
    max-width: 100%;
}

.layout {
    display: flex;
    flex-wrap: wrap;
    > * {
        flex-basis: 100%;
        order: 6;
    }
}

a {
    text-decoration: none;
    color: settings.$color-primary;
    transition: color ease-in-out 0.25s;

    &:hover {
        color: settings.$color-hover;
    }
}

.clear {
    clear: both;
}

.clsHighContrast {
    display: none !important;
    visibility: hidden !important;
}

.d-flex {
    display: flex;
}

#divFooterText {
    text-align: center;

    table,
    tbody,
    tr,
    td {
        display: block;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.sidebar-button {
    padding: 14px 8px 14px 41px;
    color: #fff;
    text-decoration: none;
    font-style: normal !important;
    font-size: 14px;
    margin-bottom: 14px;
    position: relative;
    font-family: settings.$font-family;
    transition: opacity ease-in-out 0.25s;
    display: block;
}

.sidebar-button:hover {
    color: #fff;
    opacity: 0.8;
}

.sidebar-button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    background: no-repeat center center transparent;
    background-size: contain;
}

.sidebar-button.privacy-breach {
    background-color: #00A1E4;
}

.sidebar-button.privacy-breach:before {
    width: 19px;
    height: 21px;
    background-image: url(../images/privacy.svg);
}

.sidebar-button.complaint {
    background-color: #1D74CE;
}

.sidebar-button.complaint:before {
    width: 20px;
    height: 20px;
    background-image: url(../images/complaint.svg);
}

.sidebar-button.review {
    background-color: #0552A2;
}

.sidebar-button.review:before {
    width: 20px;
    height: 20px;
    background-image: url(../images/request-review.svg);
}

.sidebar-button.access-request {
    background-color: #001E59;
}

.sidebar-button.access-request:before {
    width: 21px;
    height: 19px;
    background-image: url(../images/access-request.svg);
}

.sidebar-button.request-spoken-language {
    background-color: #061133;
}

.sidebar-button.request-spoken-language::before {
    width: 20.8px;
    height: 20.8px;
    background-image: url(../images/request-spoken-language-interpretation.svg);
}