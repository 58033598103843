﻿@use "../settings";
@use "../mixins";

@include mixins.tablet-up {
    .layout {
        .navigation-primary {
            order: 6;
        }
    }

    .navigation-primary {
        position: static;
        padding-top: 0;
        padding-bottom: 0;

        .max-width {
            padding-left: 50px;
            padding-right: 50px;
        }

        .logo {
            display: none;
            visibility: hidden;
        }

        #mobile-menu {
            + .mobile-menu-icon {
                display: none;
                visibility: hidden;
            }

            ~ .nav-items {
                position: relative;
                transform: none;
                background: transparent;
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                align-items: center;
                font-size: 1.2rem;
                gap: 24px;

                .nav-item {
                    align-items: center;
                    position: relative;

                    input[type="checkbox"] {
                        &:checked,
                        &[checked] {
                            ~ .dropdown {
                                padding-top: 18px;
                                padding-bottom: 18px;
                                overflow: visible;
                            }
                        }
                    }

                    .dropdown {
                        &::before {
                            content: "";
                            display: block;
                            border: 9px solid transparent;
                            border-top: none;
                            border-bottom-color: settings.$color-hover;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, -100%);
                        }

                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        white-space: nowrap;
                        max-width: none;
                        padding: 0 24px;
                        z-index: 100;
                        background: settings.$color-hover;
                        transition: max-height ease-in-out 0.25s, padding ease-in-out 0.25s;
                        list-style: none;
                        font-size: 1.4rem;
                        font-weight: 600;

                        li {
                            a {
                                padding: 9px 18px 9px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}