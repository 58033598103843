﻿@use "sass:math";
@use "../settings";

.header-container {
    font-size: 1.4rem;
    padding-top: 99px;

    .header {
        padding-top: 13.5px;
        padding-bottom: 28px;
    }

    .logo {
        display: none;
        visibility: hidden;
    }
}

#divMeta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .clear {
        flex-basis: 100%;
        margin-bottom: 47px;
    }

    #google_translate_element {
        margin-left: auto;
    }
}

.clsIcon {
    width: 21px;
    height: 21px;
    display: block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 150%;
    margin: 0 2px;

    &:last-child {
        margin-right: 0;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/sprite_social_media.png) no-repeat 0 0;
        transition: opacity ease-in-out 0.25s;
        display: block;
    }

    &::after {
        opacity: 0;
        background-position-y: -22px;
    }

    &:hover {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &.clsRSSButton {
        &::before,
        &::after {
            background-position-x: -21px;
        }
    }

    &.clsLinkedInButton {
        &::before,
        &::after {
            background-position-x: -42px;
        }
    }

    &.clsBlueskyButton {
        &::before,
        &::after {
            background: #adacac url(../images/blue-sky.svg) no-repeat center center;
            background-size: 13px 11.5px;
        }

        &::after {
            background-color: #00a1e4;
        }
    }
}

.clsContactUs {
    position: relative;
    padding-left: 26px;
    margin-right: 22px;
    color: #adacac;
    display: flex;
    align-items: center;
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        background: url(../images/icon_envelope.png) no-repeat top left;
        width: 16px;
        height: 13px;
        transition: opacity ease-in-out 0.25s;
        transform: translateY(-50%);
    }
    &::after {
        background-position-y: bottom;
        opacity: 0;
    }

    &:hover {
        color: settings.$color-primary;
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
}

#dropdown-language-control {
    position: relative;
    height: 1.8rem;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        pointer-events: none;
        display: block;
        border: 4px solid transparent;
        border-bottom: none;
        border-top-color: settings.$color-hover;
    }

    .combo {
        margin: 0;
        padding: 0 0 0 29px;
        background: transparent url(../images/global.svg) no-repeat left center;
        border: none;
        color: settings.$color-hover;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1.6rem;
        line-height: math.div(18, 14);
        font-family: settings.$font-family;
        width: 100%;
        outline: none !important;
        cursor: pointer;
        padding-right: 12px;

        &::-ms-expand {
            display: none;
        }
    }
}

.clsProactiveDisclosureButton {
    display: none;
}

#divSearch {
    display: flex;
    align-items: stretch;
    margin: 12px 0 20px;

    * {
        order: 4;
    }

    .clsProactiveDisclosureButton {
        order: 1;
    }
    .clsSearchBox {
        order: 2;
        flex-grow: 1;
        background: #ebebeb;
        color: settings.$color-text;
        font-size: 1.6rem;
        font-family: settings.$font-family;
        border: none;
        padding: 0 13px;
        outline: none;
    }

    .clsSearchButton {
        order: 3;
        margin: 0;
        height: 43px;
        width: 46px;
        background: url(../images/icon_magnifying_glass.png) no-repeat center center settings.$color-hover;
        transition: background-color ease-in-out 0.25s;
        display: block;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: settings.$color-primary;
        }
    }
}

.translate-advanced {
    display: flex;
    justify-content: flex-end;
}

.clsAdvancedSearch {
    font-size: 1.6rem;
}