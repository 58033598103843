@use "partials/settings";
@use "partials/mixins";
@use "partials/layout";
@use "partials/header";
@use "partials/navigation-primary";
@use "partials/breadcrumbs";
@use "partials/footer";

.clsPopupContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -10000;
    width: 100%;
    opacity: 0;
    background: #eeeced;
}

.clsPopupContainer.clsExpanded {
    opacity: 1;
    z-index: 10000;
}

.clsPopupContainer .clsPopup {
    z-index: 15000;
    background: #eeeced;
    position: relative;
    left: 0;
    bottom: 0;
    padding: 42px 0 0 0;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.5em;
    color: #426074;
}

.clsPopupContainer .clsPopup strong {
    color: #001c5b;
}

.clsPopupContainer .clsPopup p {
    margin: 0;
    padding-bottom: 32px;
}

.clsPopupContainer .clsPopup input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label {
    padding-left: 34px;
    line-height: 20px;
    position: relative;
    display: block;
    float: left;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #373536;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label::after {
    content: "✓";
    position: absolute;
    top: 10px;
    left: 12px;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%,-50%);
    color: #0a245f;
    font-weight: bold;
    opacity: 0;
}

.clsPopupContainer .clsPopup input[type="checkbox"].checked + label::after {
    opacity: 1;
}

.clsPopupContainer .clsPopup a {
    color: #01aef0;
}

.clsPopupContainer .clsPopup .clsClose {
    float: right;
}

.clsPopupContainer .clsPopup .clsPopupContent {
    padding: 0 4%;
}

.clsPopupContainer .clsPopup a:hover {
    color: #027cab;
}